import orderReportRepository from '@/repository/admin/orderReportRepository'
import klnAssessmentRepository from '@/repository/admin/klnAssessmentRepository'
import { omitEmptyFilters } from '@/helpers/filters'
import { formatDate } from '@/helpers/date'
import { DATE_DAY_FORMAT } from '@/constans/date'
import deliveryIntervalRepository from '@/repository/admin/deliveryIntervalRepository'
import i18n from '@/plugins/vue-i18n'

const { saveAs } = require('file-saver')

const actions = {
  async orderReportShippedMoreThan20Days() {
    const response = await orderReportRepository.shippedMoreThan20Days({ responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.shippedMoreThan20Days')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async orderReportShippedMoreThanDaysNoBarCode() {
    const response = await orderReportRepository.shippedMoreThanNoBarCode({ responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.shippedMoreThanDaysNoBarCode')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async approvedMoreThan14Day() {
    const response = await orderReportRepository.approvedMoreThan14Day({ responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.approvedMoreThan14Day')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async approvedMoreThan10Day() {
    const response = await orderReportRepository.approvedMoreThan10Day({ responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.approvedMoreThan10Day')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async awaitingRansomMore30Day(context, payload) {
    const id = payload.deliveryServices
    const response = await orderReportRepository.awaitingRansomMore30Day(id, {
      responseType: 'blob',
    })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.awaitingRansomMore30Day')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async awaitingRansomMore15Day(context, payload) {
    const response = await orderReportRepository.awaitingRansomMore15Day(payload, {
      responseType: 'blob',
    })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.awaitingRansomMore15Day')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async summaryPointKln(context, payload) {
    const appliedFilters = omitEmptyFilters(payload)
    const response = await klnAssessmentRepository.report(appliedFilters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.summaryPointKln')} ${formatDate(
      appliedFilters.dateFrom,
      DATE_DAY_FORMAT,
    )}-${formatDate(payload.dateTo, DATE_DAY_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async deliveryInterval(context, payload) {
    const appliedFilters = omitEmptyFilters(payload)

    const response = await deliveryIntervalRepository.report(appliedFilters, {
      responseType: 'blob',
    })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('orderReportFileName.deliveryInterval')}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async orderListReport(context, payload) {
    const appliedFilters = omitEmptyFilters(payload)

    const response = await orderReportRepository.orderListReportExport(appliedFilters, { responseType: 'blob' })
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.t('exportReports.ordersListWithQuantity')} ${formatDate(
      appliedFilters.dateFrom,
      DATE_DAY_FORMAT,
    )}-${formatDate(payload.dateTo, DATE_DAY_FORMAT)}.xlsx`
    saveAs(blob, filename)
    return response
  },
}

export default {
  namespaced: true,
  actions,
}
